.headingPoster {
  font-family: Poppins;
  font-weight: 600;
  font-size: 2rem;
  text-align: center;
  text-shadow:0 0.2rem 0.4rem rgba(0, 0, 0, 0.5);
}

.posterImg {
  margin: 5rem 2rem;
  padding: 0.5rem;
  width: 10rem;
  height: 10rem;
  box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.2);
  border-radius: 0.4rem;
}

.posterImg p {
  text-transform: capitalize;
  text-align: center;
  margin-top: 1.2rem;
  font-family: poppins;
  font-weight: 500;
}
img {
  width: 100%;
  height: 100%;
}
.posterBox {
  margin-bottom: 3rem;
}
.posterImgBox {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  justify-content: space-evenly;
  flex-wrap: wrap;
}
