*{
  box-sizing: border-box;
  padding:0;
  margin: 0;
}

.App{
  height: 100vh;
}
.main{
  min-height: 70vh;
}

.active-navlink {
  color: #E70101;
}