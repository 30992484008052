.loader {
  border: 8px solid transparent;
  border-radius: 50%;
  border-top: 8px solid #FF2731;
  border-left: 8px solid #FF2731;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}
.loader_outer_div {
  position: fixed;
  z-index: 3;
  top: 50%;
  left: 50%;
  /* background-color: rebeccapurple; */
  transform: translate(-50%, -50%);
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
