.heading {
  font-family: Poppins;
  font-weight: 700;
  margin-bottom: 1.2rem;
  font-size: 1rem;
}

.text{
font-family: Poppins;
}

.terms {
  font-family: Poppins;
  font-weight: 600;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

#modalul{
    list-style-type: circle;
    margin: 0 1rem;
}
#modalul li{
    margin-bottom: 0.3rem;
}
#OK{
    padding: 0.1rem 0.4rem;
    background-color: #000;
    color: #fff;
    font-family: Poppins;
    border-radius: 0.2rem;
    outline: none;
    box-shadow: 0 0.2rem 0.2rem #000;
    margin: 8px 0 8px 0;
}


